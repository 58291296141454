<template>
  <!-- not in use -->
  <section>
    <p class="is-block">A form to upload csv with all information.
            Csv should include email, name, project name, role.</p>
    <b-field class="file capitalize">
      <b-upload name="file" v-model="file" class="file-label" ref="file">
          <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">{{ $getTranslation('form.upload.csv') }}</span>
          </span>
          <span class="file-name is-large" v-if="file !== null">
              {{ file.name }}
          </span>
      </b-upload>
    </b-field>
    <b-field class="control">
      <b-button native-type="submit" type="is-primary" class="capitalize" v-if="file" @click="handleFileUpload">{{ $getTranslation('form.upload.label') }}</b-button>
      <b-button native-type="submit" type="is-text" class="capitalize" v-if="file" @click="file = null">Clear</b-button>
    </b-field>
  </section>
</template>

<script>
import { ApiInvitations, ApiProjects } from '@/api-routes.js'
import { mapGetters, mapActions } from 'vuex'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'

export default {
  name: 'app-send-invitation',
  mixins: [FormTemplateMixin],
  data () {
    return {
      id: 0,
      method: 'post',
      file: null
    }
  },

  computed: {
    //
  },

  methods: {
    ...mapActions([
      "fetchItems",
      "saveItem",
      "uploadFile"
    ]),

    /*
      Handles a change on the file upload
    */
    handleFileUpload(){
      console.log('HERE')
      let formData = new FormData()
      if(this.file !== null) {
        formData.append('csv_file', this.file)
        console.log(this.file, formData)
        let form = {}
        form.file = this.file
        let payload = {
          endpoint: ApiInvitations + 'uploadCsv',
          data: formData
        }
        this.uploadFile(payload)
      }
    }
  },

  async created () {
    /*try {
      await this.fetchItems(ApiProjects + '/shortList')
    } catch (error) {
      console.log('ERROR: ', error)
    }*/
  }
}
</script>
